import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Slideshow from '../components/Slideshow/Slideshow';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../components/Button/Button';

export const Head = () => {
  return (
    <Seo
      title="メニュー"
      description="肉のながおかのメニューをご紹介いたします。"
    />
  );
};

const MenuPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="Menu"
          subtitle="メニュー"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <div className="wrapper-body">
          <div className="menu-grid-wrapper">
            <div className="menu-image-left">
              <StaticImage
                formats={['auto', 'webp']}
                src="../../static/nagaoka2023/image/menu1.png"
                width={660}
                height={450}
                quality={95}
                alt="about-store-picture"
              />
            </div>
            <div className="menu-body-right" id="コース">
              <h2>
                COURSE MENU
                <br />
                ながおかのコース料理
              </h2>
              <p>
                当店では、各季節における旬の食材や その日の仕入れ状況によって
                各料理をシェフのおまかせにてご用意いたします。
              </p>
              <p>ご予算に応じて内容は変更可能ですので お気軽にご相談下さい。</p>
              <p>※お一人様11,000円から</p>
              <p>ご予算・ご要望に応じて対応させていただきます。</p>
            </div>
          </div>
          <div className="menu-grid-wrapper">
            <div className="menu-body-left">
              <h2>
                LUNCH MENU
                <br />
                ランチメニュー
              </h2>
              <p>
                当店では、和牛ならではの良さを活かした
                ステーキやパスタといった料理をコース料理として提供しています。
              </p>
              <p>
                「日々、がんばる自分にご褒美をあげたい」
                そんな皆さんは、当店自慢のランチを
                是非一度口にしてみてください。
              </p>
              <li>¥5,500コース 5品</li>
              <li>¥8,800コース 6品 </li>
              <li>¥11,000コース 7品</li>
            </div>
            <div className="menu-image-right">
              <StaticImage
                formats={['auto', 'webp']}
                src="../../static/nagaoka2023/image/menu3.png"
                width={660}
                height={450}
                quality={95}
                alt="about-store-picture"
              />
            </div>
          </div>
          <div className="menu-grid-wrapper">
            <div className="menu-image-left">
              <StaticImage
                formats={['auto', 'webp']}
                src="../../static/nagaoka2023/image/dessert.png"
                width={660}
                height={450}
                quality={95}
                alt="about-store-picture"
              />
            </div>
            <div id="デザート" className="menu-body-right">
              <h2>
                SOUVENIR&DESSERT
                <br />
                お土産・デザート
              </h2>
              <p>当店では、高級和牛を活かした豪華なお土産や</p>
              <p>
                自家製アイスクリームやテリーヌなど美味しいデザートに加えて。
              </p>
              <p>
                食材の良さを十分に活かした
                オリジナルの自家製ドレッシングを販売しております。
              </p>
              <Button
                text="お土産・デザートメニューはこちら"
                ctaLink="/dessert"
              />
            </div>
          </div>
          <div className="menu-grid-wrapper">
            <div id="お弁当" className="menu-body-left">
              <h2>
                ORIGINAL LUNCH BOX
                <br />
                ながおかのお弁当
              </h2>
              <p>
                当店では、厳選した和牛ならではの旨味と 自慢の調理方法を活かした
                お弁当の注文を承っております。
              </p>
              <p>
                「高級感あふれる和牛料理を自宅で楽しみたい」
                そんな方は是非、当店自慢のお弁当を
                一度食べてみてはいかがでしょうか。
              </p>
              <Button text="お弁当メニューはこちら" ctaLink="/bento" />
            </div>

            <div className="menu-image-right">
              <StaticImage
                formats={['auto', 'webp']}
                src="../../static/nagaoka2024/図2.png"
                width={660}
                height={450}
                quality={95}
                alt="about-store-picture"
              />
            </div>

            <div className="menu-image-left">
              <StaticImage
                formats={['auto', 'webp']}
                src="../../static/nagaoka2024/teddy-top1.jpg"
                width={660}
                height={450}
                quality={95}
                alt="about-store-picture"
              />
            </div>
            <div id="ドッグフード" className="menu-body-right">
              <h2>
                ORIGINAL DOG FOOD
                <br />
                ながおかのドックフード
              </h2>
              <p>
                当店では、高級和牛ならではの旨味と 独自の調理技術を活かして
                高級ドッグフードの販売を行っております。
              </p>

              <p>
                「大切な愛犬にも、美味しいご飯を食べて欲しい」
                そんな思いから生まれた 当店オリジナルのドッグフードを
                食べさせてみてはいかがでしょうか。
              </p>
              <p>1食:450円（税込）</p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default MenuPage;
